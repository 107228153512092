import { useState, useEffect } from 'react';
import {
  Button,
  Checkbox,
  Descriptions,
  Divider,
  PageHeader,
  Tabs,
  Typography,
  Popconfirm,
  List,
  Space,
  Modal,
  message,
  Input,
} from 'antd';
import Scrollbars from 'react-custom-scrollbars-2';
import {
  PhoneOutlined,
  EditOutlined,
  CloseOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import pinyin from 'pinyin';
import { useStopwatch } from 'react-timer-hook';
import axios from 'axios';

import './MemberInfo.css';
import DraggableTabs from '../components/draggableTabs/DraggableTabs';
import KidInfo from '../components/kidInfo/KidInfo';
const https = require('https');

const MemberInfo = ({
  incomingCall,
  childrenInfo,
  enhancedChildrenInfo,
  setEnhancedChildrenInfo,
  updateChildrenInfo,
  updateName,
  currentUser,
  apiUrl,
  computerName,
  getChildrenInfo,
  isSaveBtnLoading,
}) => {
  const [unavailable, setUnavailable] = useState(false);
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);
  const [isCompletedAndNotRated, setIsCompletedAndNotRated] = useState(false);
  const [showPopconfirm, setShowPopconfirm] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [phoneToDial, setPhoneToDial] = useState('');
  const [extNumber, setExtNumber] = useState('');
  const [isTelDisabled, setIsTelDisabled] = useState(true);
  const [isExtDisabled, setIsExtDisabled] = useState(false);
  const { minutes, reset } = useStopwatch({ autoStart: true });

  const httpsAgent = new https.Agent({ rejectUnauthorized: false });

  const handleExtInput = async () => {
    try {
      const {
        data: { data },
      } = await axios.get(
        'https://italkapi.worldfamilyenglish.com:8443/coocenter-api/plugin-asterisk/extension/extension-status',
        null,
        { httpsAgent }
      );
      const isExtValid = data.find((ext) => ext.Exten === extNumber);
      if (isExtValid) {
        const extStatus = isExtValid.Status;
        if (!extStatus.includes('OK')) {
          message.error('Please login iTalk phone first', 10);
        } else {
          window.sessionStorage.setItem('extNumber', extNumber);
          setIsExtDisabled(true);
        }
      } else {
        message.error('Ext. number is not valid', 10);
      }
      // setIsTelDisabled(true);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleSubmit = () => {
    reset();
    // setIsSubmitBtnDisabled(true);
    updateChildrenInfo(currentUser.code, unavailable ? 'N' : 'Y');
  };

  const handleDial = async () => {
    try {
      const { data } = await axios.post(
        'https://italkapi.worldfamilyenglish.com:8443/coocenter-api/plugin-asterisk/extension/click-number',
        `src=${extNumber}&dst=${phoneToDial}`,
        {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          httpsAgent,
        }
      );
      if (data.status === 'success') {
        setShowModal(false);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    const getExtNumber = () => {
      const storedExtNumber = window.sessionStorage.getItem('extNumber');
      if (storedExtNumber) {
        setTimeout(() => {
          setExtNumber(storedExtNumber);
          setIsExtDisabled(true);
        }, 500);
      }
    };
    getExtNumber();
  }, []);

  useEffect(() => {
    if (incomingCall && currentUser) {
      setPhoneToDial(incomingCall.memberPhoneNo);
      getChildrenInfo(currentUser.code, incomingCall.memberNo);
    }
  }, [incomingCall, currentUser, getChildrenInfo]);

  useEffect(() => {
    if (currentUser) {
      setExtNumber(currentUser.extNo);
    }
  }, [currentUser]);

  useEffect(() => {
    const messageConfig = {
      content: 'THIS CASE IS TAKING TOO LONG!',
      duration: 0,
      style: { color: 'crimson', fontWeight: 'bold', fontSize: 18 },
    };
    if (childrenInfo && minutes === childrenInfo.length * 8) {
      console.log(`Show warning after ${childrenInfo.length * 8} minutes.`);
      message.open(messageConfig);
    }
  }, [minutes, childrenInfo]);

  useEffect(() => {
    if (enhancedChildrenInfo) {
      const noReason = enhancedChildrenInfo.find(
        (child) => child.completeTypeCode === '6' && child.reason === ''
      );
      const notDisabled = enhancedChildrenInfo.every(
        (child) =>
          (child.completeTypeCode !== undefined && child.teComment !== '') ||
          child.completeTypeCode === '3' ||
          child.completeTypeCode === '8' ||
          child.completeTypeCode === '6'
      );
      const noTime = enhancedChildrenInfo.find(
        (child) =>
          child.completeTypeCode === '8' && child.chooseCallbackTime === ''
      );
      const noCapNotPassReason = enhancedChildrenInfo.find(
        (child) => child.capNotPassCode === '' && !child.isCapPass
      );
      setIsSubmitBtnDisabled(
        noReason || !notDisabled || noTime || noCapNotPassReason ? true : false
      );
    }
  }, [enhancedChildrenInfo]);

  useEffect(() => {
    if (enhancedChildrenInfo) {
      const completeButNoStar = enhancedChildrenInfo.find(
        (child) =>
          child.completeTypeCode === '0' &&
          child.singingStar === 0 &&
          child.pronunciationStar === 0 &&
          child.comprehensionStar === 0 &&
          child.speakingStar === 0 &&
          child.interactionStar === 0
      );
      setIsCompletedAndNotRated(completeButNoStar ? true : false);
    }
  }, [enhancedChildrenInfo]);

  return (
    childrenInfo &&
    incomingCall && (
      <>
        <div className="main-container">
          <div className="content-container">
            <PageHeader title="Member Information" />
            <Divider style={{ margin: '0 0 20px' }} />
            <div className="tabs-container">
              <Scrollbars autoHide style={{ width: '25%' }}>
                <Descriptions
                  size="small"
                  column={1}
                  layout="vertical"
                  className="page-header-description"
                >
                  <Descriptions.Item label="Member No.">
                    {incomingCall.memberNo}
                  </Descriptions.Item>
                  <Divider />
                  <Descriptions.Item label="Package Version">
                    {incomingCall.isNewVersion
                      ? '2019 版 Anna、Tommy'
                      : '2018 版 Ting Ting、Wei Wei'}
                  </Descriptions.Item>
                  <Divider />
                  <Descriptions.Item label="Tel">
                    <Space>
                      {incomingCall.memberPhoneNo}
                      {computerName &&
                        (currentUser.code === 'P550' ||
                          currentUser.code === 'P563') && (
                          <Button
                            type="primary"
                            icon={<PhoneOutlined />}
                            onClick={() => setShowModal(true)}
                          >
                            iTalk
                          </Button>
                        )}
                    </Space>
                  </Descriptions.Item>
                  <Divider />
                  {incomingCall.reasons.length > 0 && (
                    <>
                      <Descriptions.Item label="Repass Reason">
                        <List
                          size="small"
                          split={false}
                          dataSource={incomingCall.reasons}
                          renderItem={(item) => (
                            <List.Item>{`${item.nameOfChild} - ${item.reason}`}</List.Item>
                          )}
                        />
                      </Descriptions.Item>
                      <Divider />
                    </>
                  )}
                  <Descriptions.Item label="Registration Time Range (Recall Time)">
                    {incomingCall.registrationTimeRange}
                  </Descriptions.Item>
                  <Divider />
                  <Descriptions.Item label="Repass">
                    {incomingCall.numberOfRePass}
                  </Descriptions.Item>
                  <Divider />
                  <Descriptions.Item label="Note">
                    {incomingCall.notes}
                  </Descriptions.Item>
                </Descriptions>
              </Scrollbars>
              <Scrollbars autoHide>
                <div className="tabs">
                  <Typography.Title level={3}>
                    Children Information
                  </Typography.Title>
                  <DraggableTabs size="large" type="card">
                    {childrenInfo.map((child) => (
                      <Tabs.TabPane
                        tab={`${
                          child.englishName !== ''
                            ? child.englishName
                            : pinyin(child.chineseName)
                        } - ${child.chineseName}(${
                          child.sex === 'F' ? 'GIRL' : 'BOY'
                        })`}
                        key={child.seq}
                      >
                        <KidInfo
                          child={child}
                          enhancedChildrenInfo={enhancedChildrenInfo}
                          setEnhancedChildrenInfo={setEnhancedChildrenInfo}
                          incomingCall={incomingCall}
                          updateName={updateName}
                          currentUser={currentUser}
                          apiUrl={apiUrl}
                        />
                      </Tabs.TabPane>
                    ))}
                  </DraggableTabs>
                  <div className="submit-wrapper">
                    {isCompletedAndNotRated ? (
                      <Popconfirm
                        title="Not rated yet, are you sure?"
                        visible={showPopconfirm}
                        onConfirm={handleSubmit}
                        onCancel={() => setShowPopconfirm(false)}
                      >
                        <Button
                          type="primary"
                          size="large"
                          disabled={isSubmitBtnDisabled}
                          onClick={() => setShowPopconfirm(true)}
                          loading={isSaveBtnLoading}
                        >
                          Finish this call and SAVE
                        </Button>
                      </Popconfirm>
                    ) : (
                      <Button
                        type="primary"
                        size="large"
                        disabled={isSubmitBtnDisabled}
                        onClick={handleSubmit}
                        loading={isSaveBtnLoading}
                      >
                        Finish this call and SAVE
                      </Button>
                    )}
                    <Checkbox
                      checked={unavailable}
                      onChange={(e) => setUnavailable(e.target.checked)}
                      style={{ marginLeft: 20 }}
                    >
                      Set status to unavailable after finish this call
                    </Checkbox>
                  </div>
                </div>
              </Scrollbars>
            </div>
          </div>
        </div>
        <Modal
          visible={showModal}
          onCancel={() => setShowModal(false)}
          title="Dial member's number"
          width={350}
          footer={false}
        >
          <Input
            addonBefore="EXT."
            value={extNumber}
            style={{ display: 'block', marginBottom: 20 }}
            onChange={(e) => setExtNumber(e.target.value)}
            disabled={isExtDisabled}
            suffix={
              isExtDisabled ? (
                <Button
                  type="primary"
                  icon={<EditOutlined />}
                  onClick={() => setIsExtDisabled(false)}
                  size="small"
                />
              ) : (
                <Space>
                  <Button
                    icon={<CloseOutlined />}
                    onClick={() => {
                      setExtNumber(currentUser.extNo);
                      setIsExtDisabled(true);
                    }}
                  />
                  <Button
                    type="primary"
                    icon={<CheckOutlined />}
                    onClick={handleExtInput}
                  />
                </Space>
              )
            }
          />
          <Input
            addonBefore="TEL."
            value={phoneToDial}
            style={{ display: 'block', marginBottom: 20 }}
            onChange={(e) => setPhoneToDial(e.target.value)}
            disabled={isTelDisabled}
            suffix={
              isTelDisabled ? (
                <Button
                  type="primary"
                  icon={<EditOutlined />}
                  onClick={() => setIsTelDisabled(false)}
                  size="small"
                />
              ) : (
                <Space>
                  <Button
                    icon={<CloseOutlined />}
                    onClick={() => {
                      setPhoneToDial(incomingCall.memberPhoneNo);
                      setIsTelDisabled(true);
                    }}
                  />
                  <Button
                    type="primary"
                    icon={<CheckOutlined />}
                    onClick={() => setIsTelDisabled(true)}
                  />
                </Space>
              )
            }
          />
          <Button
            type="primary"
            size="large"
            block
            onClick={handleDial}
            disabled={
              phoneToDial === '' ||
              !extNumber ||
              !isExtDisabled ||
              !isTelDisabled
            }
          >
            Dial
          </Button>
        </Modal>
      </>
    )
  );
};

export default MemberInfo;
