import { Typography } from 'antd';

import './LogIn.css';
import Footer from '../components/footer/Footer';

const LogOut = () => {
  return (
    <>
      <div className="logout-container">
        <div className="wrapper">
          <Typography.Title level={3}>Log out successful</Typography.Title>
          <Typography.Title level={3}>
            You can close this window now
          </Typography.Title>
        </div>
      </div>
      <Footer bgColor="#ececec" />
    </>
  );
};

export default LogOut;
