import { useState, useEffect } from 'react';
import {
  Button,
  Form,
  Input,
  Radio,
  Rate,
  Select,
  Space,
  Tag,
  TimePicker,
  Typography,
} from 'antd';
import Timer from 'react-compound-timer';
import {
  CaretRightOutlined,
  PauseOutlined,
  RedoOutlined,
  CloseOutlined,
  EditOutlined,
  CheckOutlined,
  ClearOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import ReactAudioPlayer from 'react-audio-player';
import Scrollbars from 'react-custom-scrollbars-2';
import axios from 'axios';
import NewWindow from 'rc-new-window';

import LessonContent from '../lessonContent/LessonContent';
import History from '../history/History';

const layout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
};

const KidInfo = ({
  child,
  enhancedChildrenInfo,
  setEnhancedChildrenInfo,
  incomingCall,
  updateName,
  currentUser,
  apiUrl,
}) => {
  const [showPlayIcon, setShowPlayIcon] = useState(true);
  const [completeTypeCode, setCompleteTypeCode] = useState('0');
  const [singingStar, setSingingStar] = useState(0);
  const [pronunciationStar, setPronunciationStar] = useState(0);
  const [comprehensionStar, setComprehensionStar] = useState(0);
  const [speakingStar, setSpeakingStar] = useState(0);
  const [interactionStar, setInteractionStar] = useState(0);
  const [nameInputIsDisabled, setNameInputIsDisabled] = useState(true);
  const [newName, setNewName] = useState(child.englishName);
  const [repassTime, setRepassTime] = useState('');
  const [isCapTePassed, setIsCapTePassed] = useState(true);
  const [visible, setVisible] = useState(false);
  const [selectLesson, setSelectLesson] = useState(undefined);
  const [capNotPassReasons, setCapNotPassReasons] = useState([]);
  const [showHistory, setShowHistory] = useState(false);

  const updateStarToData = (type, setStateFn, val) => {
    if (val <= 3) {
      setStateFn(3);
    } else {
      setStateFn(val);
    }
    const updatedData = enhancedChildrenInfo.map((kid) => {
      if (kid.seq !== child.seq) {
        return kid;
      }
      if (type === 'singing') {
        return { ...kid, singingStar: val <= 3 ? 3 : val };
      }
      if (type === 'pronunciation') {
        return { ...kid, pronunciationStar: val <= 3 ? 3 : val };
      }
      if (type === 'comprehension') {
        return { ...kid, comprehensionStar: val <= 3 ? 3 : val };
      }
      if (type === 'speaking') {
        return { ...kid, speakingStar: val <= 3 ? 3 : val };
      }
      if (type === 'interaction') {
        return { ...kid, interactionStar: val <= 3 ? 3 : val };
      }
      return kid;
    });
    setEnhancedChildrenInfo(updatedData);
  };

  const updateRestToData = (type, evt, val) => {
    if (type === 'completed') {
      setCompleteTypeCode(evt.target.value);
    }
    if (type === 'incompleted') {
      setCompleteTypeCode(val);
    }
    if (type === 'isCapTePassed') {
      setIsCapTePassed(evt.target.value);
    }
    const updatedData = enhancedChildrenInfo.map((kid) => {
      if (kid.seq !== child.seq) {
        return kid;
      }
      if (type === 'teComment') {
        return {
          ...kid,
          teComment:
            incomingCall.numberOfRePass >= 2 && completeTypeCode === '3'
              ? `『已打 ${incomingCall.numberOfRePass + 1} 次』${
                  evt.target.value
                }`
              : evt.target.value,
        };
      }
      if (type === 'completed') {
        if (evt.target.value !== '0') {
          return {
            ...kid,
            completeTypeCode: undefined,
            singingStar: 0,
            pronunciationStar: 0,
            comprehensionStar: 0,
            speakingStar: 0,
            interactionStar: 0,
          };
        }
        return {
          ...kid,
          completeTypeCode: evt.target.value,
        };
      }
      if (type === 'incompleted') {
        return {
          ...kid,
          isCapPass: true,
          completeTypeCode: val,
          reason: val === '8' ? 'Call later' : '',
          teComment:
            incomingCall.numberOfRePass >= 2 && val === '3'
              ? `『已打 ${incomingCall.numberOfRePass + 1} 次』`
              : '',
        };
      }
      if (type === 'recallTime') {
        return {
          ...kid,
          chooseCallbackTime: moment(val).format('HH:mm'),
        };
      }
      if (type === 'reason') {
        return {
          ...kid,
          reason: evt.target.value,
        };
      }
      if (type === 'isCapTePassed') {
        return {
          ...kid,
          isCapPass: evt.target.value,
          capNotPassReason: evt.target.value ? '' : kid.capNotPassReason,
          capNotPassCode: evt.target.value ? '' : kid.capNotPassCode,
        };
      }
      if (type === 'capNotPassCode') {
        return {
          ...kid,
          capNotPassCode: evt.target.value,
        };
      }
      return kid;
    });
    setEnhancedChildrenInfo(updatedData);
  };

  const updateResetToData = (type) => {
    const updatedData = enhancedChildrenInfo.map((kid) => {
      if (kid.seq !== child.seq) {
        return kid;
      }
      if (type === 'singing') {
        return { ...kid, singingStar: 0 };
      }
      if (type === 'pronunciation') {
        return { ...kid, pronunciationStar: 0 };
      }
      if (type === 'comprehension') {
        return { ...kid, comprehensionStar: 0 };
      }
      if (type === 'speaking') {
        return { ...kid, speakingStar: 0 };
      }
      if (type === 'interaction') {
        return { ...kid, interactionStar: 0 };
      }
      return kid;
    });
    setEnhancedChildrenInfo(updatedData);
  };

  const handleSingingStarChange = (val) =>
    updateStarToData('singing', setSingingStar, val);
  const handleSingingReset = () => {
    setSingingStar(0);
    updateResetToData('singing');
  };

  const handlePronunciationStarChange = (val) =>
    updateStarToData('pronunciation', setPronunciationStar, val);
  const handlePronunciationReset = () => {
    setPronunciationStar(0);
    updateResetToData('pronunciation');
  };

  const handleComprehensionStarChange = (val) =>
    updateStarToData('comprehension', setComprehensionStar, val);
  const handleComprehensionReset = () => {
    setComprehensionStar(0);
    updateResetToData('comprehension');
  };

  const handleSpeakingStarChange = (val) =>
    updateStarToData('speaking', setSpeakingStar, val);
  const handleSpeakingReset = () => {
    setSpeakingStar(0);
    updateResetToData('speaking');
  };

  const handleInteractionStarChange = (val) =>
    updateStarToData('interaction', setInteractionStar, val);
  const handleInteractionReset = () => {
    setInteractionStar(0);
    updateResetToData('interaction');
  };

  const handleCommentChange = (evt) => updateRestToData('teComment', evt);

  const handleIsCompletedChange = (evt) => updateRestToData('completed', evt);

  const handleIsCapPassedChange = (evt) =>
    updateRestToData('isCapTePassed', evt);

  const handleIncompletedTypeChange = (val) =>
    updateRestToData('incompleted', null, val);

  const handleRecallTimeChange = (val) => {
    setRepassTime(moment(val).format('HH:mm'));
    updateRestToData('recallTime', null, val);
  };

  const handleReasonChange = (evt) => updateRestToData('reason', evt);

  const handleChangeName = () => {
    const nameObj = {
      memberNo: incomingCall.memberNo,
      childSeq: child.childSeq,
      teEnglishName: newName,
    };
    updateName(currentUser.code, nameObj);
    setNameInputIsDisabled(true);
  };

  const handleNotPassReasonChange = (evt) =>
    updateRestToData('capNotPassCode', evt);

  const handleDisplayContent = (lesson) => {
    setSelectLesson(null);
    setShowHistory(false);
    setTimeout(() => {
      setSelectLesson(lesson);
      setVisible(true);
    }, 500);
  };

  const handleDisplayHistory = () => {
    setSelectLesson(null);
    setVisible(false);
    setShowHistory(true);
  };

  useEffect(() => {
    const getReasons = async () => {
      try {
        const { data } = await axios.get(
          `${apiUrl}/te/${currentUser.code}/cap-failed-list`,
          { headers: { 'User-Code': currentUser.code } }
        );
        setCapNotPassReasons(data);
      } catch (error) {
        console.log(error);
      }
    };
    getReasons();
  }, [apiUrl, currentUser]);

  return (
    <>
      <Scrollbars autoHide>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Form {...layout} style={{ width: '45%' }}>
            <Form.Item label="Timer">
              <Timer
                startImmediately={false}
                formatValue={(val) => (val < 10 ? `0${val}` : val)}
              >
                {({ start, pause, reset }) => (
                  <>
                    <Timer.Minutes /> : <Timer.Seconds />
                    {showPlayIcon ? (
                      <Button
                        icon={<CaretRightOutlined />}
                        onClick={() => {
                          setShowPlayIcon(false);
                          start();
                        }}
                        type="primary"
                        style={{ margin: '0 5px' }}
                      />
                    ) : (
                      <Button
                        icon={<PauseOutlined />}
                        onClick={() => {
                          setShowPlayIcon(true);
                          pause();
                        }}
                        type="primary"
                        style={{ margin: '0 5px' }}
                      />
                    )}
                    <Button
                      icon={<RedoOutlined />}
                      onClick={() => {
                        setShowPlayIcon(true);
                        reset();
                        pause();
                      }}
                      type="primary"
                    />
                  </>
                )}
              </Timer>
            </Form.Item>
            <Form.Item label="Age">
              {child.age}{' '}
              <Button
                size="small"
                type="primary"
                shape="round"
                onClick={handleDisplayHistory}
              >
                History
              </Button>{' '}
              {child.graduateFlag === 'Y' && (
                <Tag color="sandybrown">Graduated</Tag>
              )}{' '}
              {child.superKidsFlag === 'Y' && (
                <Tag color="sandybrown">Super kid</Tag>
              )}
            </Form.Item>
            <Form.Item label="Lessons">
              {child.registrationSongs.map((lesson) => (
                <div key={lesson.songNo}>
                  <Typography.Text>
                    {lesson.songName}
                    <br />
                    {lesson.songUrl !== '' && (
                      <ReactAudioPlayer
                        src={lesson.songUrl}
                        controls
                        style={{ width: '80%' }}
                        controlsList="nodownload"
                      />
                    )}
                  </Typography.Text>
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => handleDisplayContent(lesson)}
                    shape="round"
                    style={{ marginBottom: 10 }}
                  >
                    Display Lesson Content
                  </Button>
                </div>
              ))}
            </Form.Item>
            <Form.Item label="Completed">
              <Radio.Group
                buttonStyle="solid"
                size="small"
                onChange={handleIsCompletedChange}
                defaultValue={completeTypeCode}
              >
                <Radio.Button value="0">YES</Radio.Button>
                <Radio.Button value="no">NO</Radio.Button>
              </Radio.Group>
            </Form.Item>
            {child.capFlag === 'Y' && completeTypeCode === '0' && (
              <Form.Item label="CAP status">
                <Radio.Group
                  buttonStyle="solid"
                  size="small"
                  onChange={handleIsCapPassedChange}
                  defaultValue={isCapTePassed}
                >
                  <Radio.Button value={true}>Pass</Radio.Button>
                  <Radio.Button value={false}>NOT Pass</Radio.Button>
                </Radio.Group>
              </Form.Item>
            )}
            {!isCapTePassed && completeTypeCode === '0' && (
              <Form.Item label="Reason of CAP not pass">
                <Radio.Group onChange={handleNotPassReasonChange}>
                  <Space direction="vertical">
                    {capNotPassReasons.length > 0 &&
                      capNotPassReasons.map((reason) => (
                        <Radio
                          key={reason.notPassCode}
                          value={reason.notPassCode}
                        >
                          <span style={{ fontSize: 13 }}>
                            {reason.notPassDesc}
                          </span>
                        </Radio>
                      ))}
                  </Space>
                </Radio.Group>
              </Form.Item>
            )}
            {completeTypeCode !== undefined && completeTypeCode !== '0' && (
              <Form.Item label="Incompleted Type">
                <Select
                  style={{ width: 200 }}
                  onChange={handleIncompletedTypeChange}
                >
                  <Select.Option value="3">No Answer</Select.Option>
                  <Select.Option value="8">Call Later</Select.Option>
                  <Select.Option value="6">Other</Select.Option>
                </Select>
              </Form.Item>
            )}
            {completeTypeCode === '8' && (
              <Form.Item label="Choose Time">
                <TimePicker
                  format="HH:mm"
                  minuteStep={5}
                  defaultValue={repassTime === '' && moment()}
                  onChange={handleRecallTimeChange}
                  disabledHours={() => {
                    const hours = [];
                    for (let i = 0; i < moment().hour(); i++) {
                      hours.push(i);
                    }
                    return hours;
                  }}
                  disabledMinutes={(selectedHour) => {
                    const minutes = [];
                    if (selectedHour === moment().hour()) {
                      for (let i = 0; i < moment().minute(); i++) {
                        minutes.push(i);
                      }
                    }
                    return minutes;
                  }}
                  allowClear={false}
                />
              </Form.Item>
            )}
            {completeTypeCode !== '0' &&
              completeTypeCode !== '8' &&
              completeTypeCode !== '3' && (
                <Form.Item
                  label="Reason of Incompleted"
                  onChange={handleReasonChange}
                >
                  <Input.TextArea showCount maxLength={500} rows={3} />
                </Form.Item>
              )}
          </Form>
          <Form {...layout} style={{ width: '45%' }}>
            <Form.Item label="New English Name">
              <Input
                defaultValue={child.englishName}
                disabled={nameInputIsDisabled}
                onChange={(e) => setNewName(e.target.value)}
              />
              {nameInputIsDisabled ? (
                <Button
                  icon={<EditOutlined />}
                  style={{ position: 'absolute', right: 0, top: 0 }}
                  onClick={() => setNameInputIsDisabled(false)}
                  type="primary"
                />
              ) : (
                <>
                  <Button
                    icon={<CloseOutlined />}
                    style={{ position: 'absolute', right: 32, top: 0 }}
                    onClick={() => setNameInputIsDisabled(true)}
                  />
                  <Button
                    icon={<CheckOutlined />}
                    style={{ position: 'absolute', right: 0, top: 0 }}
                    onClick={handleChangeName}
                    type="primary"
                  />
                </>
              )}
            </Form.Item>
            {child.ageNo >= 1 && completeTypeCode === '0' && (
              <>
                <Form.Item label="Singing">
                  <Button
                    icon={<ClearOutlined />}
                    size="small"
                    onClick={handleSingingReset}
                    style={{ marginRight: 7 }}
                    disabled={
                      child.capFlag === 'Y' && child.capSongFlag === 'N'
                    }
                  />
                  <Rate
                    count={5}
                    onChange={handleSingingStarChange}
                    className="star"
                    value={singingStar}
                    disabled={
                      child.capFlag === 'Y' && child.capSongFlag === 'N'
                    }
                  />
                </Form.Item>
                <Form.Item label="Pronunciation">
                  <Button
                    icon={<ClearOutlined />}
                    size="small"
                    onClick={handlePronunciationReset}
                    style={{ marginRight: 7 }}
                  />
                  <Rate
                    count={5}
                    onChange={handlePronunciationStarChange}
                    className="star"
                    value={pronunciationStar}
                  />
                </Form.Item>
              </>
            )}
            {child.ageNo >= 2 && completeTypeCode === '0' && (
              <>
                <Form.Item label="Comprehension">
                  <Button
                    icon={<ClearOutlined />}
                    size="small"
                    onClick={handleComprehensionReset}
                    style={{ marginRight: 7 }}
                  />
                  <Rate
                    count={5}
                    onChange={handleComprehensionStarChange}
                    className="star"
                    value={comprehensionStar}
                  />
                </Form.Item>
                <Form.Item label="Speaking">
                  <Button
                    icon={<ClearOutlined />}
                    size="small"
                    onClick={handleSpeakingReset}
                    style={{ marginRight: 7 }}
                  />
                  <Rate
                    count={5}
                    onChange={handleSpeakingStarChange}
                    className="star"
                    value={speakingStar}
                  />
                </Form.Item>
              </>
            )}
            {(child.ageNo < 1 || child.ageNo >= 2) && completeTypeCode === '0' && (
              <Form.Item label="Interaction">
                <Button
                  icon={<ClearOutlined />}
                  size="small"
                  onClick={handleInteractionReset}
                  style={{ marginRight: 7 }}
                />
                <Rate
                  count={child.ageNo < 1 ? 3 : 5}
                  onChange={handleInteractionStarChange}
                  className="star"
                  value={interactionStar}
                />
              </Form.Item>
            )}
            <Form.Item label="Comment">
              {incomingCall.numberOfRePass >= 2 && completeTypeCode === '3' && (
                <span>{`『已打 ${incomingCall.numberOfRePass + 1} 次』`}</span>
              )}
              <Input.TextArea
                showCount
                maxLength={500}
                rows={3}
                onChange={handleCommentChange}
              />
            </Form.Item>
          </Form>
        </div>
      </Scrollbars>
      {selectLesson &&
        (visible ? (
          <NewWindow
            onClose={() => {
              setShowHistory(false);
              setSelectLesson(null);
              setVisible(false);
            }}
            title={selectLesson.songName}
            width={1200}
            height={900}
          >
            <LessonContent
              songName={selectLesson.songName}
              songNo={selectLesson.songNo}
              apiUrl={apiUrl}
              userCode={currentUser.code}
            />
          </NewWindow>
        ) : null)}
      {showHistory ? (
        <NewWindow
          onClose={() => {
            setSelectLesson(null);
            setVisible(false);
            setShowHistory(false);
          }}
          title="History"
          width={1200}
          height={900}
        >
          <History
            apiUrl={apiUrl}
            userCode={currentUser.code}
            memberNo={child.memNo}
            chineseName={child.chineseName}
            englishName={child.englishName}
          />
        </NewWindow>
      ) : null}
    </>
  );
};

export default KidInfo;
