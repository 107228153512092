import { useEffect, useState } from 'react';
import axios from 'axios';
import { Table } from 'antd';
import pinyin from 'pinyin';

const History = ({ apiUrl, userCode, memberNo, chineseName, englishName }) => {
  const [history, setHistory] = useState([]);

  const columns = [
    {
      title: 'Date',
      dataIndex: 'teDate',
      width: 180,
    },
    {
      title: '報名項目',
      dataIndex: 'contents',
      render: (text) => text.map((lesson) => <p key={lesson}>{lesson}</p>),
      width: 300,
    },
    {
      title: 'Notes',
      dataIndex: 'crewNotes',
    },
  ];

  useEffect(() => {
    const getHistory = async () => {
      try {
        const { data } = await axios.get(
          `${apiUrl}/te/history-comment/${memberNo}`,
          {
            headers: { 'User-Code': userCode },
          }
        );
        setHistory(data);
      } catch (error) {
        console.log(error.message);
      }
    };
    getHistory();
  }, [apiUrl, memberNo, userCode]);

  return (
    history.length > 0 && (
      <div style={{ padding: 30 }}>
        <p>
          {chineseName}{' '}
          {englishName !== '' ? `- ${englishName}` : `- ${pinyin(chineseName)}`}
        </p>
        <Table
          rowKey="seq"
          dataSource={history}
          columns={columns}
          pagination={false}
        />
      </div>
    )
  );
};

export default History;
