import { Button, message, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneVolume } from '@fortawesome/free-solid-svg-icons';
import { LoginOutlined } from '@ant-design/icons';

import './LogIn.css';
import { loginRequest } from '../auth/authConfig';
import Footer from '../components/footer/Footer';

const LogIn = ({ instance }) => {
  const handleLogIn = () => {
    instance.loginPopup(loginRequest).catch((err) => {
      console.log(err.message);
      message.error(
        `Log in failed. Reason: ${err.message} Please try again.`,
        10
      );
    });
  };

  return (
    <>
      <div className="container">
        <div className="wrapper">
          <Typography.Title level={1} style={{ marginBottom: 50 }}>
            <FontAwesomeIcon icon={faPhoneVolume} style={{ marginRight: 10 }} />
            TE Crew
          </Typography.Title>
          <Button
            onClick={handleLogIn}
            className="sign-in-btn"
            icon={<LoginOutlined style={{ marginRight: 10 }} />}
          >
            Log In with Microsoft Account
          </Button>
        </div>
      </div>
      <Footer bgColor="#ececec" />
    </>
  );
};

export default LogIn;
