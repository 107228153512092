import { Layout, Button, Typography, Switch } from 'antd';
import {
  LogoutOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneVolume } from '@fortawesome/free-solid-svg-icons';
import { withRouter } from 'react-router-dom';

import './Header.css';

const Header = ({
  currentUser,
  isCrewAvailabled,
  isSwitchDisabled,
  updateCrewStatus,
  logout,
  setNextCall,
  history,
}) => {
  const handleLogOut = () => {
    logout(currentUser.code);
    setNextCall(null);
    history.push('/logout');
  };

  const handleAvailableChange = (checked) =>
    updateCrewStatus(currentUser.code, checked);

  return (
    <Layout.Header className="header">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography.Title level={2}>
          <FontAwesomeIcon icon={faPhoneVolume} style={{ marginRight: 10 }} />
          TE Crew
        </Typography.Title>
        <div className="switch">
          Crew Available?
          <Switch
            checked={isCrewAvailabled}
            onChange={handleAvailableChange}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            style={{ marginLeft: 10 }}
            disabled={isSwitchDisabled}
          />
        </div>
      </div>
      <div className="sign-out-container">
        <p>Hello, {`${currentUser.englishName} (${currentUser.code})`}</p>
        <Button
          onClick={handleLogOut}
          className="sign-out-btn"
          icon={<LogoutOutlined />}
          size="small"
          disabled={isCrewAvailabled}
        >
          Log Out
        </Button>
      </div>
    </Layout.Header>
  );
};

export default withRouter(Header);
