import { useState, useEffect, useCallback } from 'react';
import { Button, Divider, Radio, Space, Typography, Popover } from 'antd';
import axios from 'axios';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';

const LessonContent = ({ songNo, apiUrl, userCode, songName }) => {
  const [version, setVersion] = useState(1);
  const [latestContent, setLatestContent] = useState(null);
  const [formerContent, setFormerContent] = useState(null);
  const [fontSize, setFontSize] = useState(20);

  const getLessonContent = useCallback(
    async (version) => {
      try {
        const { data } = await axios.get(
          `${apiUrl}/te/${songNo}/${version}/lesson-content`,
          { headers: { 'User-Code': userCode } }
        );
        if (version === 1) {
          setLatestContent(data);
        } else {
          setFormerContent(data);
        }
      } catch (error) {
        console.log(error.message);
      }
    },
    [songNo, apiUrl, userCode]
  );

  useEffect(() => {
    getLessonContent(version);
  }, [getLessonContent, version]);

  useEffect(() => {
    if (version === 0 && !formerContent) {
      getLessonContent(version);
    }
  }, [version, formerContent, getLessonContent]);

  return (
    <Space
      direction="vertical"
      style={{ padding: 30, backgroundColor: 'white', width: '100%' }}
    >
      <div style={{ position: 'fixed', right: 20, top: 20 }}>
        <Space>
          <Button
            onClick={() => setFontSize(fontSize - 1)}
            icon={<MinusCircleOutlined />}
          />
          <Button
            onClick={() => setFontSize(fontSize + 1)}
            icon={<PlusCircleOutlined />}
          />
        </Space>
      </div>
      <Radio.Group
        defaultValue={1}
        buttonStyle="solid"
        onChange={(e) => setVersion(e.target.value)}
      >
        <Radio.Button value={1}>Latest Version</Radio.Button>
        <Radio.Button value={0}>Former Version</Radio.Button>
      </Radio.Group>
      {version === 1
        ? latestContent && (
            <div
              style={{
                backgroundImage:
                  'url(https://twclubstd.blob.core.windows.net/taiwan-club/data/te-crew-img/MicrosoftTeams-image.png)',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography.Title level={3}>{songName}</Typography.Title>
              {latestContent.map((item, i) => {
                return (
                  <Space direction="vertical" key={i}>
                    <pre style={{ whiteSpace: 'pre-wrap', fontSize }}>
                      {item.content}
                    </pre>
                    {item.imageUrl !== '' && (
                      <Popover
                        content={
                          <img
                            src={item.imageUrl}
                            style={{ width: 640 }}
                            alt=""
                          />
                        }
                        trigger="click"
                        placement="rightTop"
                      >
                        <img
                          src={item.imageUrl}
                          style={{ width: 320, cursor: 'pointer' }}
                          alt=""
                        />
                      </Popover>
                    )}
                  </Space>
                );
              })}
            </div>
          )
        : formerContent && (
            <div
              style={{
                backgroundImage:
                  'url(https://twclubstd.blob.core.windows.net/taiwan-club/data/te-crew-img/MicrosoftTeams-image.png)',
              }}
            >
              <Typography.Title level={3}>{songName}</Typography.Title>
              {formerContent.map((item, i) => {
                return (
                  <Space direction="vertical" key={i}>
                    <pre style={{ whiteSpace: 'pre-wrap', fontSize }}>
                      {item.content}
                    </pre>
                    {item.imageUrl !== '' && (
                      <Popover
                        content={
                          <img
                            src={item.imageUrl}
                            style={{ width: 640 }}
                            alt=""
                          />
                        }
                        trigger="click"
                        placement="rightTop"
                      >
                        <img
                          src={item.imageUrl}
                          style={{ width: 320, cursor: 'pointer' }}
                          alt=""
                        />
                      </Popover>
                    )}
                  </Space>
                );
              })}
            </div>
          )}
      <Divider />
      <Typography.Paragraph>
        All rights reserved. No portion of book pages may be reproduced or
        shared in any form without permission from ©Disney & World Family
        English Ltd..
      </Typography.Paragraph>
    </Space>
  );
};

export default LessonContent;
