import { useEffect } from 'react';
import { Button, Divider, Empty, Form, PageHeader, Table, message } from 'antd';
import { Scrollbars } from 'react-custom-scrollbars-2';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import './WaitingList.css';

const layout = {
  labelCol: { span: 14 },
  wrapperCol: { span: 10 },
};
const columns = [
  {
    title: 'Member No.',
    dataIndex: 'memberNo',
  },
  {
    title: 'Completed Children',
    dataIndex: 'numberOfRegisteredKids',
  },
  {
    title: 'Registration Time Range (Recall Time)',
    dataIndex: 'registrationTimeRange',
  },
];

const WaitingList = ({
  history,
  setIsSwitchDisabled,
  incomingCall,
  finishedCalls,
}) => {
  const handleGoClick = () => {
    setIsSwitchDisabled(true);
    history.push(`/${moment().format('YYYY-MM-DD')}/${incomingCall.memberNo}`);
  };

  useEffect(() => {
    message.destroy();
  }, []);

  return (
    <div className="main-container-list">
      <div className="content-container-waiting-list">
        <PageHeader title="Waiting List" />
        <Divider style={{ margin: '0 0 20px' }} />
        <div className="card-wrapper">
          <div className="first-card">
            {incomingCall ? (
              <>
                <Form {...layout} className="next-call">
                  <Form.Item label="Member Number">
                    {incomingCall.memberNo}
                  </Form.Item>
                  <Form.Item label="The Number of Registered Kids">
                    {incomingCall.numberOfRegisteredKids}
                  </Form.Item>
                  <Form.Item label="Registration Time Range (Recall Time)">
                    {incomingCall.registrationTimeRange}
                  </Form.Item>
                </Form>
                <Button
                  type="primary"
                  size="large"
                  onClick={handleGoClick}
                  style={{ width: 300, fontSize: 24, margin: ' 0 auto' }}
                >
                  Enter to the next call
                </Button>
              </>
            ) : (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <>
                    <p>No Incoming Call</p>
                    <p>Please Wait or Change Status to Available</p>
                  </>
                }
                style={{ marginTop: 250 }}
              />
            )}
          </div>
          <Scrollbars style={{ height: 'auto' }} autoHide>
            <div className="second-card">
              <Table
                rowKey={(record) => uuidv4()}
                columns={columns}
                dataSource={finishedCalls}
                pagination={false}
              />
            </div>
          </Scrollbars>
        </div>
      </div>
    </div>
  );
};

export default WaitingList;
