import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import 'antd/dist/antd.css';

import './index.css';
import AppLoader from './AppLoader';
import { msalConfig } from './auth/authConfig';

// MSAL authentication
const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <BrowserRouter>
    <MsalProvider instance={msalInstance}>
      <AppLoader />
    </MsalProvider>
  </BrowserRouter>,
  document.getElementById('root')
);
