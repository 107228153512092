import { useState, useCallback, useEffect, useContext } from 'react';
import { Layout, message } from 'antd';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import axios from 'axios';

import './App.css';
import ConfigContext from './ConfigContext';
import LogIn from './pages/LogIn';
import WaitingList from './pages/WaitingList';
import MemberInfo from './pages/MemberInfo';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Spinner from './components/spinner/Spinner';
import ErrorFallback from './components/errorFallback/ErrorFallback';
import { loginRequest } from './auth/authConfig';
import { callMsGraph } from './auth/graphConfig';
import LogOut from './pages/LogOut';

const App = ({ history }) => {
  const [isError, setIsError] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  const [isCrewAvailabled, setIsCrewAvailabled] = useState(false);
  const [isSwitchDisabled, setIsSwitchDisabled] = useState(false);
  const [graphData, setGraphData] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [finishedCalls, setFinishedCalls] = useState([]);
  const [nextCall, setNextCall] = useState(null);
  const [nextCallMemberInfo, setNextCallMemberInfo] = useState(null);
  const [childrenInfo, setChildrenInfo] = useState(null);
  const [enhancedChildrenInfo, setEnhancedChildrenInfo] = useState(null);
  const [computerName, setComputerName] = useState(null);
  const [isSaveBtnLoading, setIsSaveBtnLoading] = useState(false);
  const { apiUrl } = useContext(ConfigContext);

  // MSAL authentication
  const { instance, accounts, inProgress } = useMsal();
  const requestProfileData = useCallback(() => {
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        callMsGraph(response.accessToken).then((response) =>
          setGraphData(response)
        );
      })
      .catch((err) => {
        message.error('User authentication failed. Please re-login again', 0);
        window.localStorage.clear();
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      });
  }, [instance, accounts]);

  // Get next call function
  const getNextCall = useCallback(
    async (userCode) => {
      try {
        console.log('Getting next call...');
        const { data } = await axios.get(`${apiUrl}/te/${userCode}/next-call`, {
          headers: { 'User-Code': userCode },
        });
        setNextCall(data);
        if (data.available === 'Y') {
          setIsCrewAvailabled(true);
        } else {
          setIsCrewAvailabled(false);
        }
      } catch (error) {
        console.log(error);
        message.error('Get user status failed. Please refresh.', 5);
        setIsError(true);
      }
    },
    [apiUrl]
  );

  // Get finished calls list function
  const getFinishedCalls = useCallback(
    async (userCode) => {
      try {
        console.log('Getting finished calls list...');
        const { data } = await axios.get(
          `${apiUrl}/te/${userCode}/finished-calls`,
          {
            headers: { 'User-Code': userCode },
          }
        );
        setFinishedCalls(data);
      } catch (error) {
        console.log(error);
        message.error('Get finished calls list failed. Please refresh.', 5);
        setIsError(true);
      }
    },
    [apiUrl]
  );

  // Get member information function
  const getMemberInfo = useCallback(
    async (code, memNo) => {
      try {
        console.log('Getting next call member info...');
        const { data } = await axios.get(
          `${apiUrl}/te/${code}/calling/${memNo}/member-info`,
          {
            headers: { 'User-Code': code },
          }
        );
        setNextCallMemberInfo(data);
      } catch (error) {
        console.log(error);
        message.error('Get member information failed. Please refresh.', 5);
        setIsError(true);
      }
    },
    [apiUrl]
  );

  // Get children information function
  const getChildrenInfo = useCallback(
    async (crewCode, memNo) => {
      try {
        console.log('Getting children information...');
        const { data } = await axios.get(
          `${apiUrl}/te/${crewCode}/calling/${memNo}/children-info`,
          {
            headers: { 'User-Code': crewCode },
          }
        );
        setChildrenInfo(data);
        const enhanceData = data.map((item) => {
          return {
            seq: item.seq,
            singingStar: 0,
            pronunciationStar: 0,
            comprehensionStar: 0,
            speakingStar: 0,
            interactionStar: 0,
            teComment: '',
            completeTypeCode: '0',
            chooseCallbackTime: '',
            reason: '',
            childSeq: item.childSeq,
            isCapPass: true,
            capNotPassCode: '',
          };
        });
        setEnhancedChildrenInfo(enhanceData);
      } catch (error) {
        console.log(error);
        message.error(
          "Get next call's children information failed. Please refresh.",
          5
        );
        setIsError(true);
      }
    },
    [apiUrl]
  );

  // Update crew status function
  const updateCrewStatus = useCallback(
    async (crewNo, boolean) => {
      console.log(`Changing crew available status to ${boolean}...`);
      try {
        const { data } = await axios.patch(
          `${apiUrl}/te/crew/avaliable`,
          {
            crewNo,
            available: boolean ? 'Y' : 'N',
          },
          {
            headers: { 'User-Code': crewNo },
          }
        );
        if (!data) {
          message.error('You cannot change status now. Please try later.');
        }
        if (data && boolean) {
          setIsCrewAvailabled(boolean);
        }
        if (data && !boolean) {
          setIsCrewAvailabled(boolean);
        }
      } catch (error) {
        console.log(error);
        message.error('Update crew status failed. Please refresh.', 5);
        setIsError(true);
      }
    },
    [apiUrl]
  );

  // Update next call children info function
  const updateChildrenInfo = async (crewNo, yOrN) => {
    console.log('Data saving...');
    setIsSaveBtnLoading(true);
    const finalData = {
      crewNo: currentUser.code,
      memberNo: nextCallMemberInfo.memberNo,
      regTime: nextCallMemberInfo.regTime,
      callback: nextCallMemberInfo.callback,
      numberOfRePass: nextCallMemberInfo.numberOfRePass,
      available: yOrN,
      teSaveDetails: enhancedChildrenInfo,
    };
    try {
      const { data } = await axios.post(`${apiUrl}/te/save`, finalData, {
        headers: { 'User-Code': crewNo },
      });
      if (!data) {
        console.log('Call API success but save failed.');
        // const resetedData = childrenInfo.map((item) => {
        //   return {
        //     seq: item.seq,
        //     singingStar: 0,
        //     pronunciationStar: 0,
        //     comprehensionStar: 0,
        //     speakingStar: 0,
        //     interactionStar: 0,
        //     teComment: '',
        //     completeTypeCode: '0',
        //     chooseCallbackTime: '',
        //     reason: '',
        //     childSeq: item.childSeq,
        //   };
        // });
        // setIsCrewAvailabled(yOrN === 'N' ? false : true);
        // setEnhancedChildrenInfo(resetedData);
        message.error(
          'Saving data failed. Server is busy. Please save again',
          5
        );
        setIsSaveBtnLoading(false);
      } else {
        setNextCall(null);
        setNextCallMemberInfo(null);
        setChildrenInfo(null);
        setEnhancedChildrenInfo(null);
        if (yOrN === 'N') {
          setIsCrewAvailabled(false);
          setIsSwitchDisabled(false);
        } else {
          setIsCrewAvailabled(true);
          getNextCall(currentUser.code);
        }
        getFinishedCalls(currentUser.code);
        setIsSaveBtnLoading(false);
        history.push('/');
      }
    } catch (error) {
      console.log(error, 'Call API failed');
      message.error('Saving data failed. Please try again later.', 5);
      setIsSaveBtnLoading(false);
    }
  };

  // Log out function
  const logout = async (crewNo) => {
    try {
      await axios.post(
        `${apiUrl}/te/crew/logout`,
        { crewNo },
        {
          headers: { 'User-Code': crewNo },
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  // Update child's english name function
  const updateName = async (crewNo, nameObj) => {
    console.log('Updating english name...');
    try {
      const { data } = await axios.patch(
        `${apiUrl}/te/child-info/english-name`,
        nameObj,
        {
          headers: { 'User-Code': crewNo },
        }
      );
      if (!data) {
        console.log('Call API success but update failed. Please refresh.');
        message.error('Update english name failed. Please try again later.', 5);
      } else {
        message.success("Child's english name changed.", 5);
      }
    } catch (error) {
      console.log(error, 'Call API failed');
      message.error('Update english name failed. Please try again later.', 5);
    }
  };

  // Get user profile from microsoft
  useEffect(() => {
    if (accounts.length > 0) {
      requestProfileData();
    }
  }, [accounts, requestProfileData]);

  // Get current user info
  useEffect(() => {
    const getCurrentUser = async (email) => {
      try {
        console.log('Getting user info...');
        const { data } = await axios.get(`${apiUrl}/te-crew/information`, {
          params: { email, computerName },
        });
        setCurrentUser(data);
      } catch (error) {
        console.log(error);
        if (error.message.includes('404')) {
          message.error(
            'Your account is not a crew member. Please logout and try again.',
            5
          );
          setTimeout(() => {
            instance.logout();
          }, 5000);
        } else {
          message.error('Get user information failed. Please refresh.', 5);
        }
      }
    };
    if (graphData) {
      getCurrentUser(graphData.mail);
    }
  }, [graphData, instance, apiUrl, computerName]);

  // Get crew status and finished calls list after login
  useEffect(() => {
    if (currentUser) {
      getNextCall(currentUser.code);
      getFinishedCalls(currentUser.code);
    }
  }, [currentUser, getFinishedCalls, getNextCall]);

  // Get incoming call
  useEffect(() => {
    if (isCrewAvailabled && currentUser) {
      getNextCall(currentUser.code);
    }
  }, [isCrewAvailabled, currentUser, getNextCall]);

  // Get incoming call member info or recall api
  useEffect(() => {
    let repeat;
    if (nextCall && nextCall.memNo === '' && isCrewAvailabled && currentUser) {
      repeat = setInterval(() => {
        getNextCall(currentUser.code);
      }, 3000);
    } else if (nextCall && nextCall.memNo !== '' && isCrewAvailabled) {
      clearInterval(repeat);
      getMemberInfo(currentUser.code, nextCall.memNo);
    }
    return () => clearInterval(repeat);
  }, [nextCall, currentUser, isCrewAvailabled, getNextCall, getMemberInfo]);

  // If there is a next call or children info exist, make switch disabled
  useEffect(() => {
    if (nextCallMemberInfo || childrenInfo) {
      setIsSwitchDisabled(true);
      return () => setIsSwitchDisabled(false);
    }
  }, [setIsSwitchDisabled, nextCallMemberInfo, childrenInfo]);

  // Disable right click menu to prevent audio download
  useEffect(() => {
    document.addEventListener('contextmenu', (e) => e.preventDefault());
    return () =>
      document.removeEventListener('contextmenu', (e) => console.log(e));
  }, []);

  // Check is ip there from query string
  useEffect(() => {
    const hasComputerName = window.location.href.split('?')[1];
    if (hasComputerName) {
      console.log('Work from office');
      const computerName = hasComputerName.split('=')[1];
      setComputerName(computerName);
    } else {
      console.log('Work from home');
    }
  }, []);

  if (accounts.length > 0) {
    if (isError) {
      return <ErrorFallback />;
    }
    return currentUser ? (
      <Layout>
        <Header
          currentUser={currentUser}
          isCrewAvailabled={isCrewAvailabled}
          isSwitchDisabled={isSwitchDisabled}
          updateCrewStatus={updateCrewStatus}
          logout={logout}
          setNextCall={setNextCall}
        />
        <Switch>
          <Route
            exact
            path="/"
            render={(routePros) => (
              <WaitingList
                {...routePros}
                setIsSwitchDisabled={setIsSwitchDisabled}
                incomingCall={nextCallMemberInfo}
                finishedCalls={finishedCalls}
              />
            )}
          />
          <Route
            exact
            path="/:today/:memberNo"
            render={(routePros) => (
              <MemberInfo
                {...routePros}
                incomingCall={nextCallMemberInfo}
                childrenInfo={childrenInfo}
                enhancedChildrenInfo={enhancedChildrenInfo}
                updateChildrenInfo={updateChildrenInfo}
                setEnhancedChildrenInfo={setEnhancedChildrenInfo}
                updateName={updateName}
                currentUser={currentUser}
                apiUrl={apiUrl}
                computerName={computerName}
                getChildrenInfo={getChildrenInfo}
                isSaveBtnLoading={isSaveBtnLoading}
              />
            )}
          />
          <Route exact path="/logout" render={() => <LogOut />} />
          <Redirect to="/" />
        </Switch>
        <Footer />
      </Layout>
    ) : (
      <Spinner text="Loading..." />
    );
  } else if (inProgress === 'login') {
    return <Spinner text="Logging In..." />;
  } else {
    return <LogIn instance={instance} />;
  }
};

export default withRouter(App);
